import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { BaseEmoji, Picker } from "emoji-mart";
import { MutableRefObject, useCallback, useEffect, useState, VFC } from "react";

export type EmojiPickerProps = {
  open: boolean;
  disabled?: boolean;
  anchorElRef: MutableRefObject<HTMLElement> | null;
  onClose: (closed: boolean) => void;
  onSelect?: (value: string) => void;
};

export const EmojiPicker: VFC<EmojiPickerProps> = ({ open, disabled, anchorElRef, onClose, onSelect }) => {
  const [internalOpen, setInternalOpen] = useState(open);

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  const manageClickOutside = useCallback(
    (e) => {
      // if (
      //   document.activeElement !== anchorElRef.current &&
      //   e.target !== anchorElRef.current &&
      //   !isDescendant("reclaim-datetime-picker-ui", e.relatedTarget)
      // ) {
      setInternalOpen(false);
      onClose(false);
      // }
    },
    [onClose]
  );

  return (
    <Popper
      open={(!disabled && !!anchorElRef?.current && internalOpen) || false}
      placement="bottom-start"
      anchorEl={anchorElRef?.current}
      style={{ zIndex: 10000 }}
      modifiers={{
        offset: {
          enabled: false,
          offset: "100%",
        },
      }}
    >
      <ClickAwayListener onClickAway={manageClickOutside}>
        <Picker
          onSelect={(emoji: BaseEmoji) => {
            let value = emoji.native;

            if (onSelect) onSelect(value);

            setInternalOpen(false);
            onClose(false);
          }}
          title="Pick your emoji…"
          emoji="point_up"
        />
      </ClickAwayListener>
    </Popper>
  );
};
